import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import CustomButton from '../../components/CustomButton';
import Label from '../../components/Label';
import { TextAreaWithCounterLimit } from '../TextAreaWithCounterLimit';

// Prime-React-Components
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { confirmDialog } from 'primereact/confirmdialog';

// Constants
import {
  BUTTON_TYPES,
  CODE_QUALITY,
  FEEDBACK_TEXTAREA_LIMIT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  InterviewMode,
  InterviewType,
  Interview_Category,
  LABEL_TYPES,
  PRACTICAL_RESULT,
  STATUS,
  STATUS_INTERVIEW,
  TECHNICAL_FEEDBACK_PARAMETERS,
  TEXTAREA_LIMIT
} from '../../constants/common';
import { ROUTES, OVERALL_GRADE } from '../../constants/common';

// Redux-Actions
import { addFeedback, getInterviewHistoryList } from '../../redux/actions/interviews';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';

// Utils
import { wordCapitalize } from '../../utils/common';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/interview-round`;

const AddFeedback = (props) => {
  const navigate = useNavigate();
  // React useRef hook
  const fileRef = useRef(null);

  // Redux hooks.
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state);
  const { isLoading } = useSelector((state) => state.apiStatus);

  // React useState hooks.
  const {
    id,
    disabled,
    interviewType,
    interviewRoundId,
    interviewRoundMode,
    candidateId,
    confirmDialogMessage,
    interviewRound,
    interviewCategory
  } = props;

  const [feedbackData, setFeedbackData] = useState({
    Ratings: {},
    technicalAttitude: '',
    interviewRoundMode: interviewRoundMode || '',
    technicalCommunication: '',
    // overallFeedback: '',
    overallGrade: '',
    codeQuality: '',
    taskCompletion: 0,
    feedbackNote: '',
    interviewRoundId: '',
    jobDesignation: '',
    package: '',
    experience: '',
    practicalCommunication: '',
    codeReview: '',
    canDoAttitude: '',
    candidateImage: '',
    practicalDefinition: '',
    practicalPerformedUsing: '',
    // practicalStartTime: '',
    // practicalEndTime: '',
    result: '',
    spendTime: '',
    candidateExpectation: ''
  });

  // React useEffect hooks.
  useEffect(() => {
    if (id && userDetails && Object.keys(userDetails).length !== 0) {
      setFeedbackData({
        ...feedbackData,
        interviewRoundId: userDetails?.InterviewRoundId || '',
        codeQuality: `${userDetails?.CodeQuality || ''}`,
        feedbackNote: `${userDetails?.FeedbackNote || ''}`,
        taskCompletion: Number(userDetails?.TaskCompletion) || 0,
        // overallFeedback: `${userDetails?.OverAllFeedback || ''}`,
        overallGrade: `${userDetails?.OverAllGrades || ''}`,
        technicalAttitude: `${userDetails?.TechnicalAttitude || ''}`,
        technicalCommunication: `${userDetails?.TechnicalCommunication || ''}`,
        practicalCommunication: `${userDetails?.PracticalCommunication || ''}`,
        codeReview: `${userDetails?.CodeReview || ''}`,
        canDoAttitude: `${userDetails?.CanDoAttitude || ''}`,
        candidateImage: `${userDetails?.candidateImage || ''}`,
        practicalDefinition: `${userDetails?.PracticalDefinition || ''}`,
        practicalPerformedUsing: `${userDetails?.PracticalPerformedUsing || ''}`,
        // practicalStartTime: userDetails?.PracticalStartTime
        //   ? new Date(userDetails?.PracticalStartTime)
        //   : '',
        // practicalEndTime: userDetails?.PracticalEndTime
        //   ? new Date(userDetails?.PracticalEndTime)
        //   : '',
        result: `${userDetails?.Result || ''}`,
        spendTime: `${userDetails?.SpendTime || ''}`,
        Ratings: setRatings(userDetails.Ratings)
      });
    }
  }, [userDetails]);

  const setRatings = (data) => {
    const parametersObject = {};
    TECHNICAL_FEEDBACK_PARAMETERS.forEach((param) => {
      parametersObject[param] = data?.[param] || 0;
    });
    return parametersObject;
  };

  const IsPendingInvite = (data) => {
    return data.some((invite) => invite.Status === STATUS.PENDING);
  };

  let validationShape;
  const commonValidationShape = {
    overallGrade: Yup.string().required('Please select an overall grade'),
    result: Yup.string().required('Please enter result'),
    interviewerID: Yup.array().when(['status'], {
      is: (value) =>
        Number(value) === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
        Number(value) === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
        Number(value) === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
        Number(value) === STATUS_INTERVIEW.PRACTICAL_PENDING ||
        Number(value) === STATUS_INTERVIEW.HR_PENDING ||
        (Number(value) === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE &&
          IsPendingInvite(interviewRound.InterviewRoundInvitations)) ||
        (Number(value) === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE &&
          IsPendingInvite(interviewRound.InterviewRoundInvitations)) ||
        (Number(value) === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE &&
          IsPendingInvite(interviewRound.InterviewRoundInvitations)) ||
        (Number(value) === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE &&
          IsPendingInvite(interviewRound.InterviewRoundInvitations)) ||
        (Number(value) === STATUS_INTERVIEW.HR_RESCHEDULE &&
          IsPendingInvite(interviewRound.InterviewRoundInvitations)),
      then: (schema) => {
        let requiredSchema = schema;
        if (interviewRound?.InterviewerLimit) {
          requiredSchema = requiredSchema.length(
            interviewRound.InterviewerLimit,
            `Max ${interviewRound.InterviewerLimit} interviewers can be selected.`
          );
        }
        return requiredSchema;
      },
      otherwise: Yup.array()
    }),
    candidateImage: Yup.string().when(['interviewRoundMode'], {
      is: (value) => Number(value) !== InterviewMode.Online,
      then: Yup.string(),
      otherwise: Yup.string().required('Please select candidate image')
    }),
    feedbackNote: Yup.string()
      .trim()
      .min(
        interviewCategory === Interview_Category['Non Technical'] ? 4 : TEXTAREA_LIMIT.MIN,
        'Feedback note should be at least ${min} characters.'
      )
      .max(FEEDBACK_TEXTAREA_LIMIT.MAX, 'Feedback note should not exceed ${max} characters.')
      .nullable()
      .required('Please enter notes.')
  };

  if (
    props.interviewType === InterviewType['Technical Interview - 1'] ||
    props.interviewType === InterviewType['Technical Interview - 2'] ||
    props.interviewType === InterviewType['Management Round']
  ) {
    validationShape = Yup.object().shape({
      ...commonValidationShape,
      technicalAttitude: Yup.string().required('Please select an attitude grade'),
      technicalCommunication: Yup.string().required('Please select communication grade')
      // overallFeedback: Yup.string().required('Please select feedback')
    });
  } else if (props.interviewType === InterviewType.Practical) {
    validationShape = Yup.object().shape({
      ...commonValidationShape,
      taskCompletion: Yup.number()
        .min(1, 'Task completion minimum 1% required')
        .max(100, 'Task completion should be max 100%')
        .required('Please enter code quality'),
      codeQuality: Yup.string().required('Please enter code quality'),
      practicalCommunication: Yup.string().required('Please enter communication grade'),
      codeReview: Yup.string().required('Please enter code review'),
      canDoAttitude: Yup.string().required('Please enter can do attitude'),
      practicalDefinition: Yup.string().required('Please enter practical definition'),
      practicalPerformedUsing: Yup.string().required('Please enter technology used'),
      // practicalStartTime: Yup.string().required('Please enter interview start time'),
      // practicalEndTime: Yup.string().required('Please enter interview end time'),
      spendTime: Yup.number()
        .min(1, 'Minimum 1 Min')
        .max(480, 'Maximum 480 Mins')
        .required('Please enter spend time')
    });
  } else if (props.interviewType === InterviewType['HR Round']) {
    validationShape = Yup.object().shape({
      ...commonValidationShape,
      // overallFeedback: Yup.string().required('Please select feedback'),
      overallGrade: Yup.string().required('Please select an overall grade'),
      jobDesignation: Yup.string().required('Please select an designation'),
      package: Yup.string()
        .typeError('package must be a number')
        .required('Please enter package value'),
      experience: Yup.number()
        .min(1, 'Minimum 1 Year')
        .max(20, 'Maximum 20 Years')
        .typeError('experience must be a number')
        .required('Please enter experience'),
      candidateExpectation: Yup.string()
        .typeError('candidate expectation must be a number')
        .required('Please enter candidate expectation')
    });
  }

  const formik = useFormik({
    initialValues: { ...feedbackData, interviewerID: '', status: interviewRound?.Status },
    validationSchema: validationShape,
    enableReinitialize: true,
    onSubmit: (values) => {
      confirmFeedbackSubmission(values);
    }
  });

  const submitFeedbackAfterConfirmation = (values) => {
    const interviewRoundId = userDetails?.InterviewRoundId;
    const payload = new FormData();
    let formData = {
      interviewRoundId: id,
      // overallFeedback: values.overallFeedback.toString(),
      feedbackNote: values.feedbackNote,
      overallGrades: values.overallGrade.toString(),
      codeQuality: values.codeQuality.toString(),
      taskCompletion: values.taskCompletion ? values.taskCompletion.toString() : '',
      technicalAttitude: values.technicalAttitude.toString(),
      technicalCommunication: values.technicalCommunication.toString(),
      practicalCommunication: values.practicalCommunication.toString(),
      codeReview: values.codeReview.toString(),
      canDoAttitude: values.canDoAttitude.toString(),
      practicalDefinition: values.practicalDefinition.toString(),
      practicalPerformedUsing: values.practicalPerformedUsing.toString(),
      // practicalStartTime: values.practicalStartTime,
      // practicalEndTime: values.practicalEndTime,
      ...(typeof values.candidateImage === 'object' && { candidateImage: values.candidateImage }),
      ...(!!values.interviewerID?.length && { invitationIds: values.interviewerID }),
      result: values.result.toString(),
      ratings: values.Ratings,
      spendTime: values.spendTime ? values.spendTime.toString() : '',
      candidateExpectations: ''
    };
    if (interviewType === InterviewType['HR Round']) {
      formData = {
        ...formData,
        designation: values.jobDesignation,
        packagePerAnum: values.package.toString(),
        experience: values.experience.toString(),
        candidateExpectations: values.candidateExpectation.toString()
      };
      dispatch(apiStatusClear());
      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          payload.append(key, key === 'ratings' ? JSON.stringify(formData[key]) : formData[key]);
        }
      }
      dispatch(addFeedback(payload, interviewRoundId));
      props.onHide(false);
    } else {
      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          payload.append(key, key === 'ratings' ? JSON.stringify(formData[key]) : formData[key]);
        }
      }
      dispatch(addFeedback(payload, interviewRoundId));
      // Fetch the latest interview history list if interview type is not HR interview.
      setTimeout(() => {
        if (!props.ByHR) {
          dispatch(
            getInterviewHistoryList({
              searchValue: '',
              pageNumber: INITIAL_PAGE_NUMBER,
              size: INITIAL_SIZE,
              sortBy: 'DateModified',
              orderBy: 'desc'
            })
          );
          navigate(ROUTES.INTERVIEWER.INTERVIEW_HISTORY);
        }
      }, [250]);
      props.onHide(false);
      dispatch(apiStatusClear());
    }
  };

  const confirmFeedbackSubmission = (values) => {
    confirmDialog({
      header: 'Confirmation',
      message:
        confirmDialogMessage || 'Are you sure you want to submit the feedback for this candidate?',
      icon: 'pi pi-question-circle',
      accept: () => {
        submitFeedbackAfterConfirmation(values);
      },
      reject: () => {}
    });
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  function renameFile(originalFile, newFileName) {
    return new File([originalFile], newFileName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified
    });
  }

  function getNewFileName(originalFile) {
    const originalName = originalFile.name;
    const originalExtension = originalName.slice(originalName.lastIndexOf('.'));
    const baseName = originalName.slice(0, originalName.lastIndexOf('.'));
    const dateTimeString = new Date().toISOString().replace(/[:.-]/g, ''); // Format date-time string

    return `${baseName}-${dateTimeString}${originalExtension}`;
  }

  // const setDefaultTime = (currentValue, value, minDateTime) => {
  //   let selectedDate = value ? moment(value) : value;

  //   if (currentValue && moment(currentValue).isSame(value, 'day')) {
  //     selectedDate = value;
  //   } else {
  //     if (selectedDate) {
  //       const minDateMoment = moment(minDateTime);
  //       if (
  //         selectedDate.isSame(minDateMoment, 'day') &&
  //         selectedDate.isSameOrBefore(minDateMoment, 'time')
  //       ) {
  //         selectedDate = selectedDate
  //           .hour(minDateMoment.hour())
  //           .minute(minDateMoment.minute())
  //           .second(minDateMoment.second())
  //           .millisecond(minDateMoment.millisecond());
  //       } else {
  //         selectedDate = selectedDate.hour(9).minute(0).second(0).millisecond(0);
  //       }
  //     }
  //     selectedDate = selectedDate.toDate();
  //   }
  //   return selectedDate;
  // };

  return (
    <div className="form-box-wrapper feedback-form-popup">
      <form>
        <div className="form-row-wrapper">
          {interviewType === InterviewType['Technical Interview - 1'] ||
          interviewType === InterviewType['Technical Interview - 2'] ||
          interviewType === InterviewType['Management Round'] ? (
            <>
              {formik.values.Ratings && (
                <>
                  {Object.keys(formik.values.Ratings)?.map((param, j) => (
                    <>
                      <div className="form-col full-width" key={j}>
                        <div className="form-group-outer">
                          <div className="custom-form-group">
                            <Label
                              htmlFor="ratings"
                              text={
                                `${wordCapitalize(param)}` +
                                ': ' +
                                `${formik.values.Ratings?.[param]}`
                              }
                            />
                            <div className="flex align-center">
                              <i
                                className="pi pi-minus-circle pr-3"
                                onClick={() => {
                                  if (formik.values.Ratings?.[param] !== 0) {
                                    formik.setFieldValue('Ratings', {
                                      ...formik.values.Ratings,
                                      [param]: formik.values.Ratings?.[param] - 0.5
                                    });
                                  }
                                }}
                                style={{
                                  cursor: 'pointer',
                                  color: '#7367f0',
                                  pointerEvents: disabled ? 'none' : 'auto'
                                }}
                              />
                              <Slider
                                value={formik.values.Ratings?.[param]}
                                name={param}
                                onChange={(e) => {
                                  console.log(e.value);
                                  formik.setFieldValue('Ratings', {
                                    ...formik.values.Ratings,
                                    [param]: e.value
                                  });
                                }}
                                onBlur={formik.handleBlur}
                                min={0}
                                max={5}
                                step={0.5}
                                disabled={disabled}
                                className="mt-2 grow-1"
                              />
                              <i
                                className="pi pi-plus-circle pl-3"
                                onClick={() => {
                                  if (formik.values.Ratings?.[param] !== 5) {
                                    formik.setFieldValue('Ratings', {
                                      ...formik.values.Ratings,
                                      [param]: formik.values.Ratings?.[param] + 0.5
                                    });
                                  }
                                }}
                                style={{
                                  cursor: 'pointer',
                                  color: '#7367f0',
                                  pointerEvents: disabled ? 'none' : 'auto'
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              )}
            </>
          ) : null}

          {interviewType === InterviewType['Technical Interview - 1'] ||
          interviewType === InterviewType['Technical Interview - 2'] ||
          interviewType === InterviewType['Management Round'] ? (
            <>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="address" text="Select an attitude grade" isMandatory />
                    <Dropdown
                      name="technicalAttitude"
                      id="technicalAttitude"
                      optionLabel="name"
                      optionValue="code"
                      value={formik.values.technicalAttitude}
                      options={OVERALL_GRADE}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabled}
                      placeholder="Select an attitude"
                      className={classNames({
                        'p-invalid': isFormFieldValid('technicalAttitude'),
                        'w-full': true
                      })}
                    />
                    {getFormErrorMessage('technicalAttitude')}
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="address" text="Select communication grade" isMandatory />
                    <Dropdown
                      name="technicalCommunication"
                      id="technicalCommunication"
                      optionLabel="name"
                      optionValue="code"
                      value={formik.values.technicalCommunication}
                      options={OVERALL_GRADE}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabled}
                      placeholder="Select communication"
                      className={classNames({
                        'p-invalid': isFormFieldValid('technicalCommunication'),
                        'w-full': true
                      })}
                    />
                    {getFormErrorMessage('technicalCommunication')}
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="result" text="Result" isMandatory />
                    <Dropdown
                      name="result"
                      id="result"
                      optionLabel="name"
                      optionValue="code"
                      value={formik.values.result}
                      options={PRACTICAL_RESULT}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabled}
                      placeholder="Select result"
                      className={classNames({
                        'p-invalid': isFormFieldValid('result'),
                        'w-full': true
                      })}
                    />
                    {getFormErrorMessage('result')}
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="address" text="Overall grade" isMandatory />
                    <Dropdown
                      name="overallGrade"
                      id="overallGrade"
                      optionLabel="name"
                      optionValue="code"
                      value={formik.values.overallGrade}
                      options={OVERALL_GRADE}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabled}
                      placeholder="Select an overall grade"
                      className={classNames({
                        'p-invalid': isFormFieldValid('overallGrade'),
                        'w-full': true
                      })}
                    />
                    {getFormErrorMessage('overallGrade')}
                  </div>
                </div>
              </div>
              {interviewRoundMode === InterviewMode.Online && (
                <div className="form-col full-width">
                  <div className="form-group-outer">
                    <div className="custom-form-group">
                      <Label htmlFor="address" text="Select candidate image" isMandatory />
                      <div className="flex">
                        <div style={{ position: 'relative', width: '100%' }}>
                          <input
                            ref={fileRef}
                            type="file"
                            id="candidateImage"
                            name="candidateImage"
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                const file = e.target.files[0];
                                const newFileName = getNewFileName(file);
                                const renamedFile = renameFile(file, newFileName);
                                formik.setFieldValue('candidateImage', renamedFile);
                              }
                            }}
                            accept="image/png, image/jpeg ,image/jpg"
                            className={classNames({
                              'p-invalid': isFormFieldValid('candidateImage'),
                              'full-width': true
                            })}
                          />
                          {formik.values.candidateImage &&
                            typeof formik.values.candidateImage === 'object' && (
                              <span
                                className="clear-icon"
                                onClick={() => {
                                  formik.setFieldValue('candidateImage', '');
                                  fileRef.current.value = null; // Clear input field
                                }}>
                                &#x2715; {/* Unicode for the 'x' symbol */}
                              </span>
                            )}
                        </div>
                        <Button
                          type="button"
                          disabled={
                            !(userDetails?.candidateImage && interviewRoundId && candidateId)
                          }
                          onClick={() =>
                            window.open(
                              `${pathBase}/${interviewRoundId}/candidate/${candidateId}/${userDetails?.candidateImage}`,
                              '_blank'
                            )
                          }>
                          <i className="pi pi-eye"></i>
                        </Button>
                      </div>
                      {getFormErrorMessage('candidateImage')}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : interviewType === InterviewType.Practical ? (
            <>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="practicalDefinition"
                      text={`Practical Definition`}
                      isMandatory
                    />
                    <InputText
                      id="practicalDefinition"
                      name="practicalDefinition"
                      placeholder="Type practical definition"
                      value={formik.values.practicalDefinition}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabled}
                      className={classNames({
                        'p-invalid': isFormFieldValid('practicalDefinition')
                      })}
                    />
                    {getFormErrorMessage('practicalDefinition')}
                  </div>
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="practicalPerformedUsing" text="Technology Used" isMandatory />
                    <InputText
                      id="practicalPerformedUsing"
                      name="practicalPerformedUsing"
                      placeholder="Type practical performed using"
                      value={formik.values.practicalPerformedUsing}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabled}
                      className={classNames({
                        'p-invalid': isFormFieldValid('practicalPerformedUsing')
                      })}
                    />
                    {getFormErrorMessage('practicalPerformedUsing')}
                  </div>
                </div>
              </div>
              {/* <div className="form-col">
                <div className="custom-form-group">
                  <CustomDatePicker
                    value={formik.values.practicalStartTime}
                    onChange={(value) => {
                      formik.setFieldValue(
                        'practicalStartTime',
                        setDefaultTime(
                          formik.values.practicalStartTime,
                          value,
                          new Date().setFullYear(new Date().getFullYear() - 1)
                        )
                      );
                      formik.setFieldValue('practicalEndTime', '');
                    }}
                    minDateTime={new Date().setFullYear(new Date().getFullYear() - 1)}
                    maxDateTime={new Date()}
                    minTime={new Date().setHours(0, 0, 0)}
                    maxTime={new Date().setHours(23, 59, 59)}
                  />
                  {getFormErrorMessage('practicalStartTime')}
                </div>
              </div>
              <div className="form-col">
                <div className="custom-form-group">
                  <CustomDatePicker
                    dateLabel={LABEL_TYPES.END_DATE}
                    timeLabel={LABEL_TYPES.END_TIME}
                    value={formik.values.practicalEndTime}
                    onChange={(value) =>
                      formik.setFieldValue(
                        'practicalEndTime',
                        setDefaultTime(
                          formik.values.practicalEndTime,
                          value,
                          new Date(formik.values.practicalStartTime)
                        )
                      )
                    }
                    minDateTime={new Date(formik.values.practicalStartTime)}
                    minTime={new Date().setHours(0, 0, 0)}
                    maxTime={new Date().setHours(23, 59, 59)}
                    maxDateTime={new Date()}
                  />
                  {getFormErrorMessage('practicalEndTime')}
                </div>
              </div> */}
              <div className="form-col">
                <div className="form-row-wrapper">
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="spendTime" text={`Spend Time (Min)`} isMandatory />
                        <InputText
                          id="spendTime"
                          name="spendTime"
                          placeholder="In Mins"
                          type="number"
                          value={formik.values.spendTime}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          className={classNames({
                            'p-invalid': isFormFieldValid('spendTime')
                          })}
                        />
                        {getFormErrorMessage('spendTime')}
                      </div>
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="address" text="Code review" isMandatory />
                        <Dropdown
                          name="codeReview"
                          id="codeReview"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.codeReview}
                          options={OVERALL_GRADE}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          placeholder="Select code review"
                          className={classNames({
                            'p-invalid': isFormFieldValid('codeReview'),
                            'w-full': true
                          })}
                        />
                        {getFormErrorMessage('codeReview')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-row-wrapper">
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="codeQuality" text="Code quality" isMandatory />
                        <Dropdown
                          name="codeQuality"
                          id="codeQuality"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.codeQuality}
                          options={CODE_QUALITY}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          placeholder="Select code quality"
                          className={classNames({
                            'p-invalid': isFormFieldValid('codeQuality'),
                            'w-full': true
                          })}
                        />
                        {getFormErrorMessage('codeQuality')}
                      </div>
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="address" text="Communication" isMandatory />
                        <Dropdown
                          name="practicalCommunication"
                          id="practicalCommunication"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.practicalCommunication}
                          options={OVERALL_GRADE}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          placeholder="Select communication"
                          className={classNames({
                            'p-invalid': isFormFieldValid('practicalCommunication'),
                            'w-full': true
                          })}
                        />
                        {getFormErrorMessage('practicalCommunication')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-col">
                <div className="form-row-wrapper">
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="taskCompletion" text={`Task completion (%)`} isMandatory />
                        <InputText
                          id="taskCompletion"
                          name="taskCompletion"
                          placeholder="In Percentage"
                          type="number"
                          value={formik.values.taskCompletion}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          className={classNames({
                            'p-invalid': isFormFieldValid('taskCompletion')
                          })}
                        />
                        {getFormErrorMessage('taskCompletion')}
                      </div>
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="result" text="Result" isMandatory />
                        <Dropdown
                          name="result"
                          id="result"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.result}
                          options={PRACTICAL_RESULT}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          placeholder="Select result"
                          className={classNames({
                            'p-invalid': isFormFieldValid('result'),
                            'w-full': true
                          })}
                        />
                        {getFormErrorMessage('result')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-row-wrapper">
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="address" text="Overall grade" isMandatory />
                        <Dropdown
                          name="overallGrade"
                          id="overallGrade"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.overallGrade}
                          options={OVERALL_GRADE}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          placeholder="Select an overall grade"
                          className={classNames({
                            'p-invalid': isFormFieldValid('overallGrade'),
                            'w-full': true
                          })}
                        />
                        {getFormErrorMessage('overallGrade')}
                      </div>
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="address" text="Can do" isMandatory />
                        <Dropdown
                          name="canDoAttitude"
                          id="canDoAttitude"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.canDoAttitude}
                          options={OVERALL_GRADE}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          placeholder="Select can do"
                          className={classNames({
                            'p-invalid': isFormFieldValid('canDoAttitude'),
                            'w-full': true
                          })}
                        />
                        {getFormErrorMessage('canDoAttitude')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {interviewRoundMode === InterviewMode.Online && (
                <div className="form-col full-width">
                  <div className="form-group-outer">
                    <div className="custom-form-group">
                      <Label htmlFor="address" text="Select candidate image" isMandatory />
                      <div className="flex">
                        <div style={{ position: 'relative', width: '100%' }}>
                          <input
                            ref={fileRef}
                            type="file"
                            id="candidateImage"
                            name="candidateImage"
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                const file = e.target.files[0];
                                const newFileName = getNewFileName(file);
                                const renamedFile = renameFile(file, newFileName);
                                formik.setFieldValue('candidateImage', renamedFile);
                              }
                            }}
                            accept="image/png, image/jpeg ,image/jpg"
                            className={classNames({
                              'p-invalid': isFormFieldValid('candidateImage'),
                              'full-width': true
                            })}
                          />
                          {formik.values.candidateImage &&
                            typeof formik.values.candidateImage === 'object' && (
                              <span
                                className="clear-icon"
                                onClick={() => {
                                  formik.setFieldValue('candidateImage', '');
                                  fileRef.current.value = null; // Clear input field
                                }}>
                                &#x2715; {/* Unicode for the 'x' symbol */}
                              </span>
                            )}
                        </div>
                        <Button
                          type="button"
                          disabled={
                            !(userDetails?.candidateImage && interviewRoundId && candidateId)
                          }
                          onClick={() =>
                            window.open(
                              `${pathBase}/${interviewRoundId}/candidate/${candidateId}/${userDetails?.candidateImage}`,
                              '_blank'
                            )
                          }>
                          <i className="pi pi-eye"></i>
                        </Button>
                      </div>
                      {getFormErrorMessage('candidateImage')}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}

          {(interviewRound?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
            interviewRound?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
            interviewRound?.Status === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
            interviewRound?.Status === STATUS_INTERVIEW.PRACTICAL_PENDING ||
            interviewRound?.Status === STATUS_INTERVIEW.HR_PENDING ||
            (interviewRound?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE &&
              IsPendingInvite(interviewRound.InterviewRoundInvitations)) ||
            (interviewRound?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE &&
              IsPendingInvite(interviewRound.InterviewRoundInvitations)) ||
            (interviewRound?.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE &&
              IsPendingInvite(interviewRound.InterviewRoundInvitations)) ||
            (interviewRound?.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE &&
              IsPendingInvite(interviewRound.InterviewRoundInvitations)) ||
            (interviewRound?.Status === STATUS_INTERVIEW.HR_RESCHEDULE &&
              IsPendingInvite(interviewRound.InterviewRoundInvitations))) && (
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label htmlFor="interviewerID" text={LABEL_TYPES.INTERVIEWERS} />
                  <MultiSelect
                    filter
                    name="interviewerID"
                    id="interviewerID"
                    optionLabel="name"
                    optionValue="code"
                    value={formik.values.interviewerID}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    options={interviewRound.InterviewRoundInvitations.filter(
                      (invite) => invite.Status === STATUS.PENDING
                    ).map((invite) => ({
                      name: `${wordCapitalize(invite.Invited.FirstName)} ${wordCapitalize(
                        invite.Invited.LastName
                      )}`,
                      code: invite.ID
                    }))}
                    placeholder="Select"
                    display="chip"
                    className={classNames({
                      'p-invalid': isFormFieldValid('interviewerID'),
                      'w-full': true
                    })}
                  />
                </div>
                {getFormErrorMessage('interviewerID')}
              </div>
            </div>
          )}

          <div className="form-col full-width">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="Note" text="Note" isMandatory />
                <TextAreaWithCounterLimit
                  autoResize
                  placeholder="Add Note"
                  value={formik.values.feedbackNote}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched['feedbackNote'] ? formik.errors['feedbackNote'] : ''}
                  className={classNames({
                    'p-invalid': isFormFieldValid('feedbackNote')
                  })}
                  id="feedbackNote"
                  name="feedbackNote"
                  htmlFor="feedbackNote"
                  validationOnMinLimit
                  disabled={disabled}
                  minLimit={
                    interviewCategory === Interview_Category['Non Technical']
                      ? 4
                      : TEXTAREA_LIMIT.MIN
                  }
                  maxLimit={FEEDBACK_TEXTAREA_LIMIT.MAX}
                  rows={3}
                  cols={30}
                />
              </div>
            </div>
          </div>
        </div>
        {!disabled && (
          <div className="p-dialog-footer block">
            <CustomButton
              type="button"
              variant="contained"
              disabled={isLoading || disabled}
              onClick={formik.handleSubmit}>
              {BUTTON_TYPES.SUBMIT}
            </CustomButton>
          </div>
        )}
      </form>
    </div>
  );
};

const AddEditFeedback = (props) => {
  const {
    id,
    ByHR,
    onHide,
    show,
    disabled,
    interviewType,
    interviewRoundMode,
    candidateId,
    interviewRoundId,
    setIsUpdateFeedback,
    confirmDialogMessage,
    interviewRound,
    interviewCategory
  } = props;
  const { isLoading } = useSelector((state) => state.apiStatus);
  return (
    <>
      <Dialog
        header="Feedback "
        className={classNames('card overflow-hidden resize-none feedback-dialog', {
          hidden: isLoading
        })}
        visible={show}
        onHide={() => onHide(false)}>
        <AddFeedback
          id={id}
          onHide={onHide}
          interviewType={interviewType}
          interviewRoundMode={interviewRoundMode}
          confirmDialogMessage={confirmDialogMessage}
          interviewRoundId={interviewRoundId}
          candidateId={candidateId}
          disabled={disabled}
          ByHR={ByHR}
          setIsUpdateFeedback={setIsUpdateFeedback}
          interviewRound={interviewRound}
          interviewCategory={interviewCategory}
        />
      </Dialog>
    </>
  );
};

export default AddEditFeedback;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// Prime-React Components
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';

// Redux-Actions
import {
  addCommonParams,
  editCommonParames,
  getCommonParamsById
} from '../../redux/actions/commonParameter';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { updateData } from '../../redux/slices/commonParamters';

// Constants
import { BUTTON_TYPES, LABEL_TYPES, ROUTES } from '../../constants/common';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';

const AddEditCommonParams = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { show, onHide, ID } = props;
  const { parameterDetails } = useSelector((state) => state.parameter);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [parameterData, setParameterData] = useState({
    commonTechParam: ''
  });

  useEffect(() => {
    ID && dispatch(getCommonParamsById(ID));
  }, [dispatch, ID]);

  useEffect(() => {
    if (ID && parameterDetails && Object.keys(parameterDetails).length !== 0) {
      setParameterData({
        commonTechParam: parameterDetails?.ParameterName
      });
    }
  }, [parameterDetails]);

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide(false);
      formik.resetForm();
      document.body.classList.remove('sidebar-open');
      dispatch(updateData());
      dispatch(apiStatusClear());
      setParameterData({
        commonTechParam: ''
      });
      setIsUpdatedSuccess(false);
      navigate(ROUTES.ADMIN.COMMON_PARAMETERS);
    }
  }, [isSucceed]);

  useEffect(() => () => setIsUpdatedSuccess(false), []);

  const formik = useFormik({
    initialValues: parameterData,
    validationSchema: Yup.object({
      commonTechParam: Yup.string()
        .required('Parameter is required.')
        .max(30, 'Must be 30 characters or less')
    }),

    onSubmit: (values) => {
      if (ID) {
        dispatch(
          editCommonParames({
            formdata: {
              commonTechParam: values.commonTechParam
            },
            ID: ID
          })
        );
      } else {
        dispatch(
          addCommonParams({
            commonTechParam: values.commonTechParam
          })
        );
      }
      setIsUpdatedSuccess(true);
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <Sidebar
      ID={ID}
      visible={show}
      onHide={() => {
        onHide(true);
        document.body.classList.remove('sidebar-open');
      }}
      position="right"
      className="sidebar-drawer">
      <div className="form-box-wrapper">
        <div className="title-wrapper">
          <p className="card-title">{ID ? 'Edit' : 'Add'} Common Parameter</p>
          <button
            className="p-sidebar-close"
            onClick={() => {
              onHide(false);
              document.body.classList.remove('sidebar-open');
              setParameterData({
                commonTechParam: ''
              });
              formik.resetForm();
            }}>
            <span className="p-sidebar-close-icon pi pi-times" />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
          <div className="form-row-wrapper">
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="commonTechParam" text={LABEL_TYPES.PARAMETER} isMandatory />
                  <InputText
                    id="commonTechParam"
                    name="commonTechParam"
                    value={formik.values.commonTechParam}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Add Parameter *"
                    className={classNames({
                      'p-invalid': isFormFieldValid('commonTechParam')
                    })}
                  />
                </div>
                {getFormErrorMessage('commonTechParam')}
              </div>
            </div>
          </div>
          <div className="form-btn-wrapper">
            <CustomButton type="submit" variant="contained" className="" disabled={isLoading}>
              {BUTTON_TYPES.SAVE}
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => {
                onHide(false);
                document.body.classList.remove('sidebar-open');
                setParameterData({
                  commonTechParam: ''
                });
                formik.resetForm();
              }}
              color="error"
              className="gray-btn">
              {BUTTON_TYPES.CANCEL}
            </CustomButton>
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddEditCommonParams;

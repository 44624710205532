import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  parameterDetails: {},
  pagination: {}
};

const commonParameterListSlice = createSlice({
  name: 'parameter',
  initialState: initialState,
  reducers: {
    addParameterData(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination, isTechnologyDelete: false, isUpdated: false, isAdded: false };
    },
    editParameterDetails(state, action) {
      const parameterDetails = action.payload;
      return { ...state, parameterDetails };
    },
    deleteParameter(state) {
      return { ...state, isTechnologyDelete: true };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    }
  }
});

export const { addParameterData, editParameterDetails, deleteParameter, updateData } =
  commonParameterListSlice.actions;
export default commonParameterListSlice.reducer;

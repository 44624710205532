export const HR_COLUMNS = [
  { field: 'Name', header: 'Name', sortBy: 'FirstName', class: 'name-col' },
  { field: 'Email', header: 'Email' },
  { field: 'Mobile', header: 'Mobile' },
  { field: 'Experience', header: 'Exp.' },
  // { field: 'Soft Skills', header: 'Soft Skills' },
  { field: 'action', header: 'Action', isExport: false }
];

export const JOB_DESIGNATION_COLUMNS = [
  { field: 'Designation', header: 'Designation', sortBy: 'Designation', class: 'text-no-wrap' },
  { field: 'action', header: 'Action', isExport: false }
];

export const JOB_DESCRIPTION_COLUMNS = [
  { field: 'Name', header: 'Name', sortBy: 'Title', class: 'text-no-wrap' },
  { field: 'Subject', header: 'Subject', class: 'text-no-wrap' },
  { field: 'action', header: 'Action', isExport: false }
];

export const INTERVIEWERS_COLUMNS = [
  { field: 'Name', header: 'Interviewer Name', sortBy: 'FirstName', class: 'name-col' },
  { field: 'Technical Skills', header: 'Technical skills' },
  { field: 'Experience', header: 'Exp.' },
  { field: 'action', header: 'Action', isExport: false }
];

export const HR_INTERVIEWERS_COLUMNS = [
  { field: 'Name', header: 'Interviewer Name', class: 'name-col', width: '25%' },
  { field: 'Technology', header: 'Technology', width: '55%' },
  { field: 'Interviews Taken', header: 'Interviews Taken', width: '10%' },
  { field: 'Experience', header: 'Exp.', width: '10%' }
];

export const INTERVIEW_TECHNOLOGY_COLUMNS = [
  { field: 'Technology', header: 'Technology', sortBy: 'Technology' },
  { field: 'Color', header: 'Technology Color', isExport: false },
  { field: 'action', header: 'Action', isExport: false }
];

export const COMMON_PARAMS_COLUMNS = [
  { field: 'Parameters', header: 'Feedback Parameters' },
  { field: 'action', header: 'Action', isExport: false }
];
export const CANDIDATE_COLUMNS = [
  { field: 'CandidateName', header: 'Candidate Name', sortBy: 'FirstName', class: 'name-col' },
  { field: 'Technology', header: 'Technology' },
  { field: 'Experience', header: 'Exp.' },
  // { field: 'Mobile', header: 'Mobile' },
  { field: 'Resume', header: 'Resume', isExport: false },
  { field: 'HR', header: 'HR' },
  { field: 'Location', header: 'Location' },
  // { field: 'Company', header: 'Company' },
  { field: 'action', header: 'Action', isExport: false }
];

export const ADMIN_CANDIDATE_COLUMNS = [
  { field: 'CandidateName', header: 'Candidate Name', sortBy: 'FirstName', class: 'name-col' },
  { field: 'Technology', header: 'Technology' },
  { field: 'Experience', header: 'Exp.' },
  // { field: 'Mobile', header: 'Mobile' },
  { field: 'Resume', header: 'Resume', isExport: false },
  { field: 'HRName', header: 'HR' },
  { field: 'Location', header: 'Location' },
  { field: 'CurrentCompany', header: 'Company Name' }
];

export const INTERVIEW_COLUMNS = [
  { field: 'CandidateName', header: 'Candidate Name', sortBy: 'FirstName', class: 'name-col' },
  { field: 'Technology', header: 'Technology' },
  { field: 'HR', header: 'HR' },
  { field: 'Interviewer', header: 'Interviewer' },
  // { field: 'Company', header: 'Company' },
  { field: 'Status', header: 'Status', class: 'status' },
  { field: 'Result', header: 'Final Result' },
  { field: 'action', header: 'Action', isExport: false }
];
export const INTERVIEW_ROUND_COLUMNS = [
  { field: 'Interviewer Name', header: 'Interviewer Name', class: 'name-col' },
  {
    field: 'Start Date',
    header: 'Date',
    sortBy: 'StartDateOfInterviewRound',
    class: 'text-no-wrap'
  },
  { field: 'HR', header: 'HR' },
  { field: 'InterviewRound', header: 'Interview Round' },
  { field: 'RecentFeedbackBy', header: 'Update Feedback' },
  { field: 'Status', header: 'Status', class: 'status' },
  { field: 'Result', header: 'Result' },
  { field: 'action', header: 'Action', isExport: false }
];
export const INTERVIEW_INVITATIONS_COLUMNS = [
  { field: 'CandidateName', header: 'Candidate Name', sortBy: 'FirstName', class: 'name-col' },
  { field: 'InterviewType', header: 'Technology' },
  { field: 'RoundOfInterview', header: 'Interview Round' },
  // { field: 'JobDesignation', header: 'Job Designation', class: 'text-no-wrap' },
  {
    field: 'Start Date',
    header: 'Interview Date',
    class: 'text-no-wrap text-bold interview-date',
    sortBy: 'StartDateOfInterviewRound'
  },
  { field: 'HrName', header: 'Hr Name' },
  { field: 'resume', header: 'Resume', isExport: false },
  { field: 'Status', header: 'Status', sortBy: 'Status', class: 'name-col' },
  // { field: 'Description', header: 'Description', class: 'description' },
  { field: 'action', header: 'Action', isExport: false }
];
export const INTERVIEW_FEEDBACK_COLUMNS = [
  { field: 'CandidateName', header: 'Candidate Name', sortBy: 'FirstName', class: 'name-col' },
  { field: 'InterviewType', header: 'Technology' },
  { field: 'RoundOfInterview', header: 'Interview Round' },
  { field: 'JobDesignation', header: 'Job Designation', class: 'text-no-wrap' },
  {
    field: 'StartDateOfInterviewRound',
    header: 'Start Date',
    sortBy: 'StartDateOfInterviewRound',
    class: 'text-no-wrap'
  },
  { field: 'action', header: 'Action' }
];

export const SCHEDULED_INTERVIEW_COLUMNS = [
  { field: 'CandidateName', header: 'Candidate Name', sortBy: 'FirstName', class: 'name-col' },
  { field: 'Technology', header: 'Technology' },
  { field: 'RoundOfInterview', header: 'Interview Round' },
  // { field: 'JobDesignation', header: 'Job Designation', class: 'text-no-wrap' },
  {
    field: 'Start Date',
    header: 'Interview Date',
    class: 'text-no-wrap text-bold interview-date',
    sortBy: 'StartDateOfInterviewRound'
  },
  { field: 'HrName', header: 'Hr Name' },
  { field: 'resume', header: 'Resume', isExport: false },
  // { field: 'Description', header: 'Description', class: 'description' },
  { field: 'action', header: 'Action', isExport: false }
];
export const HISTORY_INTERVIEW_COLUMNS = [
  { field: 'CandidateName', header: 'Candidate Name', sortBy: 'FirstName', class: 'name-col' },
  { field: 'Technology', header: 'Technology' },
  { field: 'RoundOfInterview', header: 'Interview Round' },
  { field: 'resume', header: 'Resume', isExport: false },
  // { field: 'JobDesignation', header: 'Job Designation', class: 'text-no-wrap' },
  {
    field: 'Start Date',
    header: 'Interview Date',
    class: 'text-no-wrap text-bold interview-date',
    sortBy: 'StartDateOfInterviewRound'
  },
  // { field: 'HrName', header: 'Hr Name',},
  { field: 'Status', header: 'Status', class: 'status' },
  // { field: 'Description', header: 'Description', class: 'description' },
  { field: 'action', header: 'Action', isExport: false }
];
export const INTERVIEWER = {
  SCHEDULED_INTERVIEW_COLUMNS: [
    { field: 'CandidateName', header: 'Candidate Name', class: 'name-col' },
    { field: 'InterviewType', header: 'Technology' },
    { field: 'RoundOfInterview', header: 'Interview Round' },
    // { field: 'JobDesignation', header: 'Job Designation', class: 'text-no-wrap' },
    {
      field: 'StartDateOfInterviewRound',
      header: 'Interview Date',
      class: 'text-no-wrap text-bold interview-date'
    },
    { field: 'HrName', header: 'Hr Name' },
    { field: 'resume', header: 'Resume', isExport: false },
    // { field: 'Description', header: 'Description', class: 'description' },
    { field: 'action', header: 'Action' }
  ],
  INTERVIEW_INVITATION_COLUMNS: [
    { field: 'CandidateName', header: 'Candidate Name', class: 'name-col' },
    { field: 'InterviewType', header: 'Technology' },
    { field: 'RoundOfInterview', header: 'Interview Round' },
    // { field: 'JobDesignation', header: 'Job Designation', class: 'text-no-wrap' },
    {
      field: 'StartDateOfInterviewRound',
      header: 'Interview Date',
      class: 'text-no-wrap text-bold interview-date'
    },
    { field: 'HrName', header: 'Hr Name' },
    { field: 'resume', header: 'Resume', isExport: false },
    { field: 'Status', header: 'Status', class: 'status' },
    // { field: 'Description', header: 'Description', class: 'description' },
    { field: 'action', header: 'Action' }
  ],
  FEEDBACK_COLUMNS: [
    { field: 'CandidateName', header: 'Candidate Name', class: 'name-col' },
    { field: 'InterviewType', header: 'Technology' },
    { field: 'RoundOfInterview', header: 'Interview Round' },
    { field: 'JobDesignation', header: 'Job Designation', class: 'text-no-wrap' },
    { field: 'StartDateOfInterviewRound', header: 'Start Date', class: 'text-no-wrap' },
    { field: 'action', header: 'Action' }
  ]
};

export const HR_FEEDBACK_HISTORY_COLUMNS = [
  { field: 'FieldName', header: 'Field Name' },
  { field: 'UpdatedValue', header: 'Feedback' },
  { field: 'OldValue', header: 'Old Feedback' },
  { field: 'InterviewRound', header: 'Interview Round' },
  { field: 'UpdatedBy', header: 'Updated By' },
  // { field: 'DateModified', header: 'Last modified', sortBy: 'DateModified', class: 'text-no-wrap' },
  { field: 'DateCreated', header: 'Created Date', class: 'text-no-wrap' }
];

import api from '../../utils/axios';

//Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const commonParamsListApi = async ({ pageNumber, searchValue, sortBy, orderBy, size }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.COMMON_TECHPARAMS}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );

    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const addCommonParamsApi = async (technology) => {
  try {
    const response = await api.post(API_ROUTES.COMMON_TECHPARAMS, technology);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCommonParamsByIdApi = async (ID) => {
  try {
    const response = await api.get(`${API_ROUTES.COMMON_TECHPARAMS}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editCommonParameterApi = async (details) => {
  try {
    const response = await api.put(
      `${API_ROUTES.COMMON_TECHPARAMS}/${details.ID}`,
      details.formdata
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteParameterApi = async (ID) => {
  try {
    const response = await api.delete(`${API_ROUTES.COMMON_TECHPARAMS}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

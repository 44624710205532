import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

// Prime-React Components
import React, { useRef } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

// Constants
import { ACTION_TYPE, STATUS_INTERVIEW } from '../constants/common';

export const CustomeTooltip = () => {
  return <ReactTooltip />;
};

export const Switch = (props) => {
  return (
    <>
      <CustomeTooltip />
      <InputSwitch
        data-tip={props.UserStatus === 1 || props.UserStatus === true ? 'Active' : 'Inactive'}
        className="mx-1"
        checked={props.UserStatus === 1 || props.UserStatus === true ? true : false}
        onChange={props.onChange}
      />
    </>
  );
};

export const ActionButtons = (props) => {
  const actionMenu = useRef(null);

  const action_items = [
    {
      label: 'Edit',
      icon: 'pi pi-fw pi-pencil',
      disabled: props.disabled,
      command: () => {
        props.onClick(ACTION_TYPE.EDIT);
      }
    },
    ...(props.notes
      ? [
          {
            label: 'Notes',
            icon: 'pi pi-fw pi-list',
            disabled: props.disabled,
            command: () => {
              props.onClick(ACTION_TYPE.NOTES);
            }
          }
        ]
      : []),
    ...(props.sendJD
      ? [
          {
            label: 'Send JD',
            disabled: props.disabledSendJD,
            icon: 'pi pi-fw pi-send',
            command: () => {
              props.onClick(ACTION_TYPE.SEND_JD);
            }
          }
        ]
      : []),
    ...(props.delete
      ? [
          {
            label: 'Delete',
            icon: 'pi pi-fw pi-trash',
            disabled: props.disabled,
            command: () => {
              props.onClick(ACTION_TYPE.DELETE);
            }
          }
        ]
      : [])
  ];
  return (
    <>
      <div className="more-actions-wrapper">
        <Menu
          model={action_items}
          popup
          ref={actionMenu}
          id="action_popup_menu"
          className="popup_menu action-popup"
        />
        <Button
          label=""
          type="button"
          icon="pi pi-ellipsis-v"
          className="p-button-rounded p-button-text border-none primary-icon-btn action-popup-button"
          onClick={(event) => actionMenu.current.toggle(event)}
        />
      </div>
    </>
  );
};

export const ActionButtonWithoutView = (props) => {
  const actionMenu = useRef(null);

  const action_items = [
    {
      label: 'Edit',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        props.onClick(ACTION_TYPE.EDIT);
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-fw pi-trash',
      command: () => {
        props.onClick(ACTION_TYPE.DELETE);
      }
    }
  ];

  return (
    <>
      <div className="more-actions-wrapper">
        <Menu
          model={action_items}
          popup
          ref={actionMenu}
          id="action_popup_menu"
          className="popup_menu action-popup"
        />
        <Button
          label=""
          type="button"
          icon="pi pi-ellipsis-v"
          className="p-button-rounded p-button-text border-none primary-icon-btn action-popup-button"
          onClick={(event) => actionMenu.current.toggle(event)}
        />
      </div>
    </>
  );
};

export const ActionButtonsForInterview = (props) => {
  const actionMenu = useRef(null);
  const action_items = [
    {
      label: 'Edit',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        props.onClick(ACTION_TYPE.EDIT);
      }
    },
    {
      label: 'Round',
      icon: 'pi pi-fw pi-arrow-right',
      command: () => {
        props.onClick(ACTION_TYPE.NEXT_ROUND);
      }
    },
    {
      template: () => {
        return (
          <Link
            to="#"
            disabled={props.disabled}
            className="p-menuitem-link"
            onClick={() => {
              props.onClick(ACTION_TYPE.CANCEL);
            }}>
            <span className="pi pi-fw pi-ban p-menuitem-icon"></span>
            <span className="p-menuitem-text">Cancel</span>
          </Link>
        );
      }
    }
  ];

  return (
    <>
      <div className="more-actions-wrapper">
        <Menu
          model={action_items}
          popup
          ref={actionMenu}
          id="action_popup_menu"
          className="popup_menu action-popup"
        />
        <Button
          label=""
          type="button"
          icon="pi pi-ellipsis-v"
          className="p-button-rounded p-button-text border-none primary-icon-btn action-popup-button"
          onClick={(event) => actionMenu.current.toggle(event)}
        />
      </div>
    </>
  );
};

export const ActionButtonsForInterviewRound = (props) => {
  const { disableparameter, disabledRejection } = props;
  const actionMenu = useRef(null);
  const disabledOnCompleteAndCancelled =
    [
      STATUS_INTERVIEW.HR_COMPLETE,
      STATUS_INTERVIEW.PRACTICAL_COMPLETE,
      STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE,
      STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE,
      STATUS_INTERVIEW.MANAGEMENT_COMPLETE,
      STATUS_INTERVIEW.CANCELLED
    ].indexOf(disableparameter) !== -1;
  const action_items = [
    {
      template: () => {
        return (
          <Link
            to="#"
            className="p-menuitem-link"
            onClick={() => {
              props.onClick(ACTION_TYPE.FEEDBACK);
            }}>
            <span className="pi pi-fw pi-file p-menuitem-icon"></span>
            <span className="p-menuitem-text">Feedback</span>
          </Link>
        );
      }
    },
    {
      template: () => {
        return (
          <Link
            to="#"
            disabled={disabledOnCompleteAndCancelled}
            className="p-menuitem-link"
            onClick={() => {
              props.onClick(ACTION_TYPE.EDIT);
            }}>
            <span className="pi pi-fw pi-pencil p-menuitem-icon"></span>
            <span className="p-menuitem-text">Edit</span>
          </Link>
        );
      }
    },
    {
      template: () => {
        return (
          <Link
            to="#"
            disabled={disabledRejection}
            className="p-menuitem-link"
            onClick={() => {
              props.onClick(ACTION_TYPE.REJECT);
            }}>
            <span className="pi pi-fw pi-times p-menuitem-icon"></span>
            <span className="p-menuitem-text">Reject Candidate</span>
          </Link>
        );
      }
    },
    {
      template: () => {
        return (
          <Link
            to="#"
            disabled={disabledOnCompleteAndCancelled}
            className="p-menuitem-link"
            onClick={() => {
              props.onClick(ACTION_TYPE.CANCEL);
            }}>
            <span className="pi pi-fw pi-ban p-menuitem-icon"></span>
            <span className="p-menuitem-text">Cancel</span>
          </Link>
        );
      }
    }
  ];

  return (
    <>
      <div className="more-actions-wrapper">
        <Menu
          model={action_items}
          popup
          ref={actionMenu}
          id="action_popup_menu"
          className="popup_menu action-popup"
        />
        <Button
          label=""
          type="button"
          icon="pi pi-ellipsis-v"
          className="p-button-rounded p-button-text border-none primary-icon-btn action-popup-button"
          onClick={(event) => actionMenu.current.toggle(event)}
        />
      </div>
    </>
  );
};

export const ActionButtonsForSceduledInterview = (props) => {
  const actionMenu = useRef(null);

  const action_items = [
    {
      label: 'Feedback',
      icon: 'pi pi-fw pi-file',
      disabled: props.disabled,
      command: () => {
        props.onClick(ACTION_TYPE.FEEDBACK);
      }
    },
    {
      label: 'Cancel',
      icon: 'pi pi-fw pi-ban',
      command: () => {
        props.onClick(ACTION_TYPE.CANCEL);
      }
    }
  ];

  return (
    <>
      <div className="more-actions-wrapper">
        <Menu
          model={action_items}
          popup
          ref={actionMenu}
          id="action_popup_menu"
          className="popup_menu action-popup"
        />
        <Button
          label=""
          type="button"
          icon="pi pi-ellipsis-v"
          className="p-button-rounded p-button-text border-none primary-icon-btn action-popup-button"
          onClick={(event) => actionMenu.current.toggle(event)}
        />
      </div>
    </>
  );
};

export const ActionButtonsForInvitationInterview = (props) => {
  const actionMenu = useRef(null);

  const action_items = [
    {
      label: 'Accept',
      icon: 'pi pi-fw pi-check',
      disabled: props.disabled,
      command: () => {
        props.onClick(ACTION_TYPE.ACCEPT);
      }
    },
    {
      label: 'Reject',
      icon: 'pi pi-fw pi-times',
      disabled: props.disabled,
      command: () => {
        props.onClick(ACTION_TYPE.REJECT);
      }
    }
  ];

  return (
    <>
      <div className="more-actions-wrapper">
        <Menu
          model={action_items}
          popup
          ref={actionMenu}
          id="action_popup_menu"
          className="popup_menu action-popup"
        />
        <Button
          label=""
          type="button"
          icon="pi pi-ellipsis-v"
          className="p-button-rounded p-button-text border-none primary-icon-btn action-popup-button"
          onClick={(event) => actionMenu.current.toggle(event)}
        />
      </div>
    </>
  );
};

export const ActionButtonsForInterviewHistory = (props) => {
  const actionMenu = useRef(null);

  const action_items = [
    {
      label: 'Feedback',
      icon: 'pi pi-fw pi-file',
      disabled: props.disabled,
      command: () => {
        props.onClick(ACTION_TYPE.FEEDBACK);
      }
    }
  ];

  return (
    <>
      <div className="more-actions-wrapper">
        <Menu
          model={action_items}
          popup
          ref={actionMenu}
          id="action_popup_menu"
          className="popup_menu action-popup"
        />
        <Button
          label=""
          type="button"
          icon="pi pi-ellipsis-v"
          className="p-button-rounded p-button-text border-none primary-icon-btn action-popup-button"
          onClick={(event) => actionMenu.current.toggle(event)}
        />
      </div>
    </>
  );
};

export const ViewButton = (props) => {
  return (
    <>
      <CustomeTooltip />
      <i
        data-tip={`${props?.title ? props?.title : 'View'}`}
        onClick={props.onClick}
        className={`pi pi-fw pi-eye mx-2 view-button ${classNames(
          [props.className],
          props.className
        )}`}
      />
    </>
  );
};

export const ResumeButton = (props) => {
  return (
    <>
      <i data-tip="View" onClick={props.onClick} className="pi pi-fw pi-eye mr-2 cursor-pointer" />
    </>
  );
};

export const AvailabilityButton = (props) => {
  return (
    <>
      <CustomeTooltip />
      <i
        data-tip="Check Interviewer Availability"
        onClick={props.onClick}
        className="pi pi-fw pi-clock mx-1 text-blue-600"
      />
    </>
  );
};

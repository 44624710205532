import { ROUTES } from './common';

export const ADMIN_MENU = [
  {
    label: 'Home',
    items: [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        to: ROUTES.ADMIN.DASHBOARD
      },
      {
        label: 'HRs',
        icon: 'pi pi-fw pi-user',
        to: ROUTES.ADMIN.HRS
      },
      {
        label: 'Interviewers',
        icon: 'pi pi-fw pi-desktop',
        to: ROUTES.ADMIN.INTERVIEWERS
      },
      {
        label: 'Candidates',
        icon: 'pi pi-fw pi-users',
        to: ROUTES.ADMIN.CANDIDATES
      },
      {
        label: 'Master Data',
        icon: 'pi pi-fw pi-list',
        items: [
          {
            label: 'Job Designations',
            icon: 'pi pi-fw pi-briefcase',
            to: ROUTES.ADMIN.DESIGNATION
          },
          {
            label: 'Technologies',
            icon: 'pi pi-fw pi-code',
            to: ROUTES.ADMIN.TECHNOLOGY
          },
          {
            label: 'Job Descriptions',
            icon: 'pi pi-fw pi-database',
            to: ROUTES.ADMIN.DESCRIPTIONS
          }
          // {
          //   label: 'Feedback Parameters',
          //   icon: 'pi pi-fw pi-sliders-h',
          //   to: ROUTES.ADMIN.COMMON_PARAMETERS
          // }
        ]
      }
    ]
  }
];

export const HR_MENU = [
  {
    label: 'Home',
    items: [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        to: ROUTES.HR.DASHBOARD
      },
      {
        label: 'Interviewers',
        icon: 'pi pi-fw interviews-ic custom-ic',
        to: ROUTES.HR.INTERVIEW_SCHEDULE
      },
      {
        label: 'Candidates',
        icon: 'pi pi-fw pi-users',
        to: ROUTES.HR.CANDIDATES
      },
      {
        label: 'Interviews',
        icon: 'pi pi-fw pi-calendar',
        to: ROUTES.HR.INTERVIEWS
      }
    ]
  }
];

export const INTERVIEWER_MENU = [
  {
    label: 'Home',
    items: [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        to: ROUTES.INTERVIEWER.DASHBOARD
      },
      {
        label: 'Availability',
        icon: 'pi pi-fw clock-ic custom-ic',
        to: ROUTES.INTERVIEWER.AVAILABILITY
      },
      {
        label: 'Invitations',
        icon: 'pi pi-book',
        to: ROUTES.INTERVIEWER.INTERVIEW_INVITATION
      },
      {
        label: 'Interviews',
        icon: 'pi pi-calendar',
        to: ROUTES.INTERVIEWER.INTERVIEW_SCHEDULED
      },

      {
        label: 'History',
        icon: 'pi pi-history',
        to: ROUTES.INTERVIEWER.INTERVIEW_HISTORY
      }
    ]
  }
];
